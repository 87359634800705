

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { ElitepageStore } from '@elitepage/store/elitepage'
import { fetchSoundKit } from '@elitepage/api/sound-kits'
import { BASE_URL } from '~/const/base-url'
import { Howl } from 'howler'
import { CartStore } from '~/store/cart'

@Component({ components: {} })
export default class SoundKits extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']
    @Prop({ type: Number }) id!: ISoundKit['id']

    soundkit: ISoundKit = null
    BASE_URL = BASE_URL
    howl: Howl = null
    audioPlaying = false

    @ElitepageStore.State('elitepage') elitepage: IElitepage
    @ElitepageStore.State('producer') producer: IProducer

    @ElitepageStore.Action('fetchSingleProducer') fetchSingleProducer: (producerSlug: IProducer['slug']) => void
    @CartStore.Action('addToCart') addToCart: (cartItem: ICartItem) => void

    async togglePlayAudio() {
        if (!this.audioPlaying) {
            this.howl.play()
            this.audioPlaying = true
        } else {
            this.howl.pause()
            this.audioPlaying = false
        }
    }

    addSoundKitToCart() {
        this.addToCart({ sound_kit: this.soundkit })
        this.$store.dispatch('modal/showNotification', {
            name: 'success',
            title: 'Added!',
            content: `The sound kit was added to the cart.`
        })
    }

    async mounted() {
        this.soundkit = await fetchSoundKit(this.producer.id, this.id)
        this.howl = new Howl({ src: [`${BASE_URL}/s3/sound_kit_play/${this.soundkit.id}/`], format: ['mp3'] })
    }
}
